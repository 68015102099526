import { FormEvent, useEffect, useState } from 'react';

import './styles.css';
import PageHeader from '../../components/PageHeader';
import TeacherItem from '../../components/TeacherItem';
import Input from '../../components/Input';
import Select from '../../components/Select';
import { useDebounce } from 'use-debounce/lib';
import api from '../../services/api';

interface Teacher {
  id: number;
  subject: string;
  cost: number;
  user_id: number;
  name: string;
  avatar: string;
  whatsapp: string;
  bio: string;
}

function TeacherList() {
  const [teachers, setTeachers] = useState<Teacher[]>([])

  const [subject, setSubject] = useState('');
  const [weekDay, setWeekDay] = useState('');
  const [timeTemp, setTimeTemp] = useState('');
  const [time] = useDebounce(timeTemp, 2000);


  
  useEffect(() => {
    if( subject === '' || weekDay === '' || time === '' ){
      return;
    }

    api.get('classes', {
      params: {
        subject,
        week_day: weekDay,
        time
      }
    })
      .then(response => {
        setTeachers(response.data.classes);
      })
  }, [subject, weekDay, time])

  return (
    <div id="page-teacher-list" className="container">
      <PageHeader title="Estes são os proffys disponíveis.">
        <form id="search-teachers">
          <Select
            label="Matéria"
            name="subject"
            value={subject}
            onChange={event => setSubject(event.target.value)}
            options={[
              { value: 'Artes' },
              { value: 'Biologia' },
              { value: 'Ciências' },
              { value: 'Educação física' },
              { value: 'Física' },
              { value: 'Geografia' },
              { value: 'História' },
              { value: 'Matemática' },
              { value: 'Português' },
              { value: 'Química' }
            ]}
          />

          <Select
            label="Dia da semana"
            name="week_day"
            value={weekDay}
            onChange={event => setWeekDay(event.target.value)}
            options={[
              { value: '0', label: 'Domingo' },
              { value: '1', label: 'Segunda' },
              { value: '2', label: 'Terça' },
              { value: '3', label: 'Quarta' },
              { value: '4', label: 'Quinta' },
              { value: '5', label: 'Sexta' },
              { value: '6', label: 'Sábado' }
            ]}
          />

          <Input
            name="Hora"
            label="Hora"
            type="time"
            value={timeTemp}
            onChange={event => setTimeTemp(event.target.value)}
          />
        </form>
      </PageHeader>

      <main>
        {teachers.map(teacher => {
          return <TeacherItem key={teacher.id}  teacher={teacher}/>
        })}
      </main>
    </div>
  )
};

export default TeacherList;