import { FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';

import PageHeader from '../../components/PageHeader';
import Input from '../../components/Input';
import TextArea from '../../components/TextArea';
import Select from '../../components/Select';

import warningIcon from '../../assets/images/icons/warning.svg'

import './styles.css';
import api from '../../services/api';

function TeacherForm() {
  const [scheduleItems, setScheduleItems] = useState([{ week_day: '', from: '', to: '' }]);

  const [name, setName] = useState('');
  const [avatar, setAvatar] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [bio, setBio] = useState('');

  const [subject, setSubject] = useState('');
  const [cost, setCost] = useState('');

  const history = useHistory();


  const addNewScheduleItem = () => {
    setScheduleItems([
      ...scheduleItems,
      {
        week_day: '',
        from: '',
        to: ''
      }
    ])
  }

  const setScheduleItemValue = (position: number, field: string, value: string) => {
    const updatedScheduleItems = scheduleItems.map((scheduleItem, index) => {
      if(index === position){
        return {
          ...scheduleItem,
          [field]: value
        }
      }

      return scheduleItem;
    })

    setScheduleItems(updatedScheduleItems);
  }

  const handleCreateClass = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    api.post('classes', {
      name,
      avatar,
      whatsapp,
      bio,
      subject,
      cost: Number(cost),
  
      schedule: scheduleItems
    })
      .then(() => {
        alert('Cadastrado com Sucesso!');
        history.push('/')
      })
      .catch(() => {
        alert('Erro ao efetuar cadastro!')
      })
  }

  return (
    <div id="page-teacher-form" className="container">
      <PageHeader
        title="Que incrível que você quer dar aulas."
        description="O primeiro passo, é preencher esse formulário de inscrição."
      />
      <main>
        <form onSubmit={handleCreateClass}>
          <fieldset>
            <legend>Seus dados</legend>

            <Input 
              label="Nome completo" 
              name="name" 
              value={name} 
              onChange={(event) => setName(event.target.value)}
            />
            <Input 
              label="Avatar" 
              name="avatar" 
              value={avatar} 
              onChange={(event) => setAvatar(event.target.value)}
            />
            <Input 
              label="WhatsApp" 
              name="whatsapp" 
              value={whatsapp} 
              onChange={(event) => setWhatsapp(event.target.value)}
            />
            <TextArea 
              label="Biografia" 
              name="bio" 
              value={bio} 
              onChange={(event) => setBio(event.target.value)}
            />
          </fieldset>
          <fieldset>
            <legend>Sobre a Aula</legend>

            <Select
              label="Matéria"
              name="subject"
              value={subject} 
              onChange={(event) => setSubject(event.target.value)}
              options={[
                { value: 'Artes' },
                { value: 'Biologia' },
                { value: 'Ciências' },
                { value: 'Educação física' },
                { value: 'Física' },
                { value: 'Geografia' },
                { value: 'História' },
                { value: 'Matemática' },
                { value: 'Português' },
                { value: 'Química' }
              ]}
            />
            <Input 
              label="Custo da sua hora por aula"
              name="cost" 
              type="number"
              value={cost} 
              onChange={(event) => setCost(event.target.value)}
            />
          </fieldset>
          <fieldset>
            <legend>
              Horários disponíveis
              <button type="button" onClick={addNewScheduleItem}>
                + Novo horário
              </button>
            </legend>

            {
              scheduleItems.map((scheduleItem, index) => {
                return (
                  <div key={index} className="schedule-item">
                    <Select
                      label="Dia da semana"
                      name="week_day"
                      value={scheduleItem.week_day}
                      onChange={event => {console.log(event.target.value); setScheduleItemValue(index, 'week_day', event.target.value)}}
                      options={[
                        { value: '0', label: 'Domingo' },
                        { value: '1', label: 'Segunda' },
                        { value: '2', label: 'Terça' },
                        { value: '3', label: 'Quarta' },
                        { value: '4', label: 'Quinta' },
                        { value: '5', label: 'Sexta' },
                        { value: '6', label: 'Sábado' }
                      ]}
                    />
                    <Input 
                      name="from" 
                      label="Das" 
                      type="time"
                      value={scheduleItem.from}
                      onChange={event => setScheduleItemValue(index, 'from', event.target.value)}
                    />
                    <Input 
                      name="to" 
                      label="Time" 
                      type="time"
                      value={scheduleItem.to}
                      onChange={event => setScheduleItemValue(index, 'to', event.target.value)}
                    />
                  </div>
                )
              })
            }
          </fieldset>
          <footer>
            <p>
              <img src={warningIcon} alt="Aviso importante" />
              Importante! <br />
              Preencha todos os dados
            </p>
            <button type="submit">Salvar cadastro</button>
          </footer>
        </form>
      </main>
    </div>
  )
};

export default TeacherForm;