import './styles.css';

import whatsappIcon from '../../assets/images/icons/whatsapp.svg';
import api from '../../services/api';

interface TeacherItemProps {
  teacher: {
    id: number;
    subject: string;
    cost: number;
    user_id: number;
    name: string;
    avatar: string;
    whatsapp: string;
    bio: string;
  }
};

const TeacherItems: React.FunctionComponent<TeacherItemProps> = ({ teacher }) => {

  function createNewConnection(){
    api.post('connections', {
      user_id: teacher.user_id
    })
  }

  return (
    <article className="teacher-item">
      <header>
        <img src={teacher.avatar} alt={teacher.name} />
        <div>
          <strong>{teacher.name}</strong>
          <span>{teacher.subject}</span>
        </div>
      </header>
      <p>
        {teacher.bio}
      </p>

      <footer>
        <p>
          Preço/hora
          <strong>{teacher.cost.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</strong>
        </p>
        <a target="_blank" onClick={createNewConnection} href={`https://wa.me/${teacher.whatsapp}?text=Gostaria de saber sobre sua aula de ${teacher.subject}`} rel="noreferrer">
          <img src={whatsappIcon} alt="WhatsApp" />
          Entrar em contado
        </a>
      </footer>
    </article>
  )
}

export default TeacherItems;